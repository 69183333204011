.dealerReviewsItem {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  .review {
    border: solid #f7f8fb 1px;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    padding: 16px;
    box-shadow: 0 2px 4px 0 rgba(#414d6b, 0.16);
    box-sizing: border-box;

    .header {
      display: flex;
      padding-bottom: 16px;
      margin-bottom: 16px;
      border-bottom: solid #e9edf4 1px;
      background: url(../../../images/ic_arrow_right.svg) no-repeat right top 12px;

      .thumbnail {
        width: 40px;
        height: 40px;
        border-radius: 2px;
        margin-right: 16px;
        background-size: 40px !important;
      }

      .information {
        display: flex;
        flex-direction: column;

        .carName {
          font-size: 16px;
          line-height: 20px;
          margin-bottom: 8px;
          font-weight: bold;
          color: #272e40;
        }

        .body {
          display: flex;
          align-items: center;

          .stars {
            height: 12px;
            position: relative;
            top: -3px;

            .star {
              margin-right: 2px;
            }
          }

          .inputtedAt {
            margin-left: 4px;
            color: #98a3ad;
            font-size: 10px;
            line-height: 12px;
          }
        }
      }
    }

    .content {
      margin-bottom: 16px;
      font-size: 16px;
      line-height: 28px;
      color: #425363;
    }

    .reviewImage {
      margin-bottom: 16px;
    }
  }

  .reply {
    display: flex;
    flex-direction: column;
    margin-top: 16px;
    background: url(../../../images/ic_reply.svg) no-repeat left top;

    .content {
      margin-left: 24px;
      border-radius: 4px;
      padding: 16px;
      background: #f9faff;
      display: flex;
      flex-direction: column;
      border: solid #396eff 1px;

      .header {
        display: flex;
        margin-bottom: 16px;

        .thumbnail {
          width: 28px;
          height: 28px;
          border-radius: 50%;
          background-size: 100% !important;
        }

        .name {
          display: flex;
          align-items: center;
          font-weight: bold;
          font-size: 14px;
          line-height: 20px;
          margin-left: 8px;
        }
      }

      .text {
        font-size: 14px;
        line-height: 24px;
        white-space: pre-wrap;
      }
    }
  }
}
