.carsDetail {
  background: #396eff;
  padding: 0 32px;
  padding-top: 104px;
  padding-bottom: 108px;
  min-height: 100vh;
  box-sizing: border-box;

  .title {
    color: #fff;
    line-height: 36px;
    font-size: 24px;
    margin-bottom: 16px;
  }

  .timer {
    margin-bottom: 80px;
  }
}
