.reviewTradeResult {
  background: #f7f8fb;
  padding: 16px;
  border-radius: 4px;
  border: solid #e9edf4 1px;
  position: relative;

  .field {
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
    font-size: 14px;

    .label {
      font-weight: bold;
    }

    &.last {
      margin-bottom: 24px;
    }

    &.noReduction {
      border-bottom: solid #e9edf4 1px;
      padding-bottom: 15px;
      margin-bottom: 16px;
    }
  }

  .title {
    color: #869ab7;
    font-size: 12px;
    line-height: 16px;
    font-weight: normal;
    margin-bottom: 8px;
  }

  .content {
    display: flex;
    flex-direction: column;

    .inspectionImages {
      display: flex;
      flex-wrap: wrap;

      .inspectionImageWrapper {
        position: relative;
      }

      .inspectionImage {
        width: 44px;
        height: 44px;
        margin-bottom: 7px;
        margin-right: 7px;
        border-radius: 4px;
        background-size: 100% !important;
      }

      .count {
        position: absolute;
        left: 0;
        top: 0;
        border-radius: 4px;
        background: rgba(#10131a, .1);
        color: #fff;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
      }
    }

    .reductions {
      display: flex;
      flex-direction: column;
      padding-top: 8px;
      padding-bottom: 16px;

      .reduction {
        font-size: 12px;
        line-height: 20px;
        padding-left: 20px;
        background: url(../../../images/rectangle.png) no-repeat left top;
        background-size: 16px;
        margin-bottom: 4px;

        &:last-child {
          margin-bottom: 0;
        }

        display: flex;
        justify-content: space-between;

        .text {
          padding-right: 10px;
          color: #869ab7;
        }

        .price {
          color: #869ab7;
          white-space: nowrap;
        }
      }
    }

    .hr {
      height: 1px;
      width: 100%;
      background: #dadfe1;
    }

    .screening {
      display: flex;
      padding-top: 16px;
      justify-content: space-between;

      .name {
        font-size: 12px;
        line-height: 16px;
        color: #869ab7;
      }

      .status {
        font-size: 12px;
        font-weight: bold;
        line-height: 16px;
        color: #396eff;
        position: relative;

        &::after {
          content: '';
          left: -20px;
          top: -2px;
          position: absolute;
          width: 16px;
          height: 20px;
          background: url(../../../images/shield.svg) no-repeat center;
        }

        &.overReductions {
          color: #f5b923;

          &::after {
            background: url(../../../images/ic_guard_yellow.svg) no-repeat center;
          }
        }
      }
    }
  }

  .normalText {
    white-space: pre-wrap;
    font-size: 12px;
    line-height: 20px;

    &.old {
      display: flex;
      justify-content: space-between;

      .normalReductions {
        display: flex;
        flex-direction: column;
        max-width: 200px;

        .normalReduction {
          padding-left: 20px;
          background: url(../../../images/rectangle.png) no-repeat left top;
          background-size: 16px;
          color: #869ab7;

          span {
            &:first-child {
              margin-right: 5px;
            }
          }
        }
      }

      .normalReductionPrice {
        top: 80px;
        color: #98a3ad;
        position: absolute;
        right: 16px;
        line-height: 1;
      }
    }
  }
}
