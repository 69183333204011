.cars {
  display: flex;
  flex-direction: column;
  width: 100%;

  .header {
    padding: 20px;
    padding-bottom: 0;
    display: flex;
    justify-content: space-between;

    .label {
      font-weight: bold;
      font-size: 14px;
      line-height: 16px;
    }

    .count {
      font-size: 12px;
      color: #98a3ad;
      line-height: 14px;
    }
  }

  .items {
    width: 100%;
    padding: 15px;
    box-sizing: border-box;
  }

  .loading {
    position: fixed;
  }
}
