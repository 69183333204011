.dealerProfile {
  padding-bottom: 108px;
  .profile {
    .background {
      height: 240px;
      background-size: cover !important;
    }

    .thumbnail {
      width: 88px;
      height: 88px;
      border: solid #fff 4px;
      border-radius: 50%;
      box-sizing: border-box;
      top: 176px;
      left: 20px;
      background-color: #fff;
      position: absolute;

      background-position: center !important;
      background-size: 100% !important;
      background-repeat: no-repeat !important;
    }
  }

  .information {
    padding: 32px 24px;
    padding-bottom: 40px;

    .greeting {
      font-size: 24px;
      line-height: 36px;
      font-weight: bold;
      color: #414d6b;
      margin-bottom: 16px;
    }

    .field {
      padding-left: 20px;
      margin-bottom: 8px;
      font-size: 12px;
      color: #98a3ad;
      line-height: 16px;

      &.location {
        background: url(../../images/ic_location.svg) no-repeat left center;
      }

      &.speech {
        background: url(../../images/ic_speech.svg) no-repeat left center;
      }
    }

    .introduction {
      line-height: 28px;
      white-space: pre-wrap;
      font-size: 16px;
      padding-bottom: 20px;
      border-bottom: solid #f1f1f1 1px;
      margin-bottom: 20px;
    }

    .heydealerScreeningService {
      font-size: 12px;
      line-height: 16px;
      color: #98a3ad;
      margin-bottom: 16px;
      padding-left: 24px;
      background: url(../../images/ic_guard_gray.svg) no-repeat left center;
    }

    .screening {
      display: flex;
      flex-direction: column;
      text-decoration: none;
      color: #272e40;
      border: solid #f1f1f1 1px;
      box-shadow: 0 1px 2px 0 rgba(#425363, 0.2);
      padding: 24px 16px;
      position: relative;
      background: #f7f8fb;
      border-radius: 4px;

      .title {
        font-size: 20px;
        line-height: 20px;
        font-weight: bold;
        color: #414d6b;
        margin-bottom: 12px;
      }

      .description {
        font-size: 12px;
        line-height: 20px;

        .managerName {
          color: #396eff;
        }
      }

      .image {
        width: 100px;
        height: 120px;
        bottom: 0;
        right: 0;
        background-size: 100% !important;
        position: absolute;
      }
    }
  }

  .hr {
    background: #f3f4f6;
    height: 8px;
    width: 100%;
  }

  .reviews {
    display: flex;
    flex-direction: column;

    .header {
      padding: 16px 24px;
      padding-top: 32px;
      display: flex;
      height: 80px;
      box-sizing: border-box;
      align-items: center;
      justify-content: space-between;

      .left {
        display: flex;
        flex-direction: column;

        .highlight {
          color: #396eff;
        }

        h1 {
          font-weight: normal;
          font-size: 16px;
          line-height: 20px;
          margin-bottom: 6px;
        }

        .star {
          margin-right: 2px;
        }
      }

      .rating {
        font-size: 40px;
        line-height: 40px;
        font-family: 'Spoqa Han Sans', 'Malgun Gothic', Sans-Serif, serif;
        font-weight: bold;
      }
    }
  }
}


.sellingThisDealerButton {
  z-index: 9999;
  position: fixed;
  bottom: 16px;
  width: calc(100% - 36px);
  height: 76px;
  appearance: none;
  background: #396eff;
  left: 16px;
  border-radius: 4px;
  color: #fff;
  box-shadow: 0 16px 32px 0 rgba(#425363, 0.6);
  border: none;
  display: flex;
  flex-direction: column;

  .direction {
    position: absolute;
    width: 44px;
    height: 44px;
    background: rgba(#414d6b, 0.2);
    right: 16px;
    top: 16px;
    background-image: url(../../images/ic_arrow_up_white.svg);
    background-size: 24px !important;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 4px;
    transition: transform .3s ease-in-out;

    &.isActivated {
      transform: scaleY(-1);
    }
  }

  .content {
    overflow: hidden;
    padding-top: 16px;
    padding-left: 24px;
    padding-right: 24px;
    display: flex;
    flex-direction: column;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    transition: opacity .3s ease-in-out;

    &.isVisible {
      opacity: 1;
    }

    &.preview {
      h1 {
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 4px;
        font-weight: normal;
        text-align: left;
      }

      p {
        font-size: 12px;
        line-height: 16px;
        color: rgba(#ffffff, 0.701960784);
      }
    }

    &.after {
      h1 {
        font-size: 20px;
        line-height: 32px;
        margin-bottom: 8px;
        font-weight: 400;

        u {
          text-decoration: none;
          position: relative;

          &::after {
            content: '';
            background: #fff;
            width: 100%;
            position: absolute;
            bottom: 0;
            height: 2px;
            left: 0;
          }
        }
      }

      p {
        font-size: 14px;
        line-height: 24px;
        color: rgba(#ffffff, 0.701960784);
        display: flex;

        span {
          &:first-child {
            margin-right: 4px;
          }
        }
      }

      .button {
        position: absolute;
        bottom: 16px;
        left: 16px;
        width: calc(100% - 32px);
        background: #ffffff;
        font-size: 16px;
        line-height: 20px;
        border: none;
        border-radius: 4px;
        height: 56px;
        color: #396eff;

      }
    }
  }
}

.loading {
  position: fixed;
}
