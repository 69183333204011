.timer {
  color: #fff;
  padding-left: 24px;
  background: url(../../images/ic_timer_w.svg) no-repeat left center;
  font-size :12px;
  line-height: 24px;
  height: 24px;
  display: flex;
  align-items: center;
}
