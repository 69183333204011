.inspectionImageLoader {
  position: relative;
  overflow: hidden;

  &.car {
    .preview {
      background: url(../../images/placeholder_thumbnail_car.svg);
    }
  }

  .preview {
    width: 100%;
    height: 100%;
    position: absolute;
    background-size: 100% !important;
    background-position: center !important;
    transition: opacity .3s ease-in-out;
    opacity: 0;
    background: url(../../images/placeholder_picture.svg);

    &.isVisible {
      opacity: 1;
    }
  }

  .image {
    width: 100%;
    height: 100%;
    position: absolute;
    background-size: 100% !important;
    background-position: center !important;
    transition: opacity .3s ease-in-out;
    opacity: 0;

    &.isVisible {
      opacity: 1;
    }
  }
}
