.layer {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 9999;
  overflow: auto;
  background: #ffffff;
  -webkit-overflow-scrolling: touch;

  .content {
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    padding-top: 56px;
  }

  &.overview {
    .content {
      padding-top: 0;
    }
  }
}

.header {
  height: 56px;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: rgba(255, 255, 255, .5);
  z-index: 9999;
  transition: background .3s ease-in-out;

  .back {
    width: 56px;
    height: 56px;
    background: url(../../images/btn_back.svg) no-repeat center;
    background-size: 56px !important;
    border: none;
    appearance: none;
  }

  &.overview {
    background: none;
  }

  &.white {
    .back {
      background: url(../../images/btn_back_white.svg) no-repeat center;
      background-size: 56px !important;
    }
  }

  &.closeButton {
    .back {
      background: url(../../images/btn_close.svg) no-repeat center;
      background-size: 56px !important;
    }
  }
}
