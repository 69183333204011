.carsItem {
  width: 100%;
  display: flex;
  margin-bottom: 10px;
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 rgba(#425363, 0.2);
  padding: 20px;
  border: solid #f1f1f1 1px;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;

  .profile {
    display: flex;
    margin-right: 15px;

    .thumbnail {
      width: 75px;
      height: 75px;
      background: #efefef;
      border-radius: 4px;
      background-size: 100% !important;
    }
  }

  .information {
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .name {
      font-size: 14px;
      margin-bottom: 14px;
      margin-top: 5px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      .model {
        margin-right: 5px;
      }
    }

    .field {
      color: #869ab7;
      font-size: 12px;
      margin-bottom: 10px;
    }

    .name, .field {
      line-height: 1;
    }
  }
}
