.dealerReviews {
  .dealerPhotoReviews {
    padding: 16px 24px;
    display: flex;

    .dealerPhotoReview {
      border-radius: 2px;
      background-size: 100% !important;
      position: relative;
      overflow: hidden;

      .count {
        color: #fff;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba(#000, .5);
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        line-height: 20px;
      }
    }
  }

  .hr {
    width: calc(100% - 48px);
    height: 1px;
    background: #e9edf4;
    margin-left: 24px;
    margin-bottom: 31px;
    margin-top: 16px;
  }

  .reviews {
    padding: 0 24px;
    min-height: 200px;
  }
}

.loading {
  position: fixed;
  z-index: 99999;
}
