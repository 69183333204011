.textInput {
  display: flex;

  .input {
    display: flex;
    position: relative;
    box-sizing: border-box;
    min-width: 300px;
    overflow: hidden;
    background: #fff;
    border-radius: 4px;
    transition: border-color 0.15s cubic-bezier(0.4, 0.0, 0.2, 1), box-shadow 0.15s cubic-bezier(0.4, 0.0, 0.2, 1);
    box-shadow: inset 0 0 0 1px #dee2e6, 0 1px 2px 0 rgba(0, 0, 0, 0.05);

    .icon {
      color: #868e96;
      padding: 10px;

      svg {
        font-size: 18px;
      }
    }

    .placeholder {
      font-size: 14px;
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      left: 16px;
      height: 14px;
      pointer-events: none;
      color: #868e96;
      transition: opacity 0.15s cubic-bezier(0.4, 0.0, 0.2, 1), transform 0.15s cubic-bezier(0.4, 0.0, 0.2, 1);

      &:global(.enter) {
        opacity: 0;
        transform: translate(0px, 2px);
      }

      &:global(.enter-active) {
        opacity: 1;
        transform: translate(0px, 0px);
      }

      &:global(.exit) {
        opacity: 1;
        transform: translate(0px, 0px);
      }

      &:global(.exit-active) {
        opacity: 0;
        transform: translate(0px, 2px);
      }
    }

    input {
      font-size: 14px;
      border: none;
      box-sizing: border-box;
      width: 100%;
      height: 100%;
      padding: 12px 16px;
      background: none;
    }
  }

  &.hasIcon {
    .placeholder {
      left: 38px;
    }

    input {
      padding-left: 0;
    }
  }

  &.isFocus {
    .input {
      box-shadow: inset 0 0 0 1px #396eff, 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    }
  }
}
