.bidsItem {
  height: 144px;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(#414d6b, 0.16);
  margin-bottom: 12px;
  background: #ffffff;
  position: relative;
  padding: 0 20px;
  box-sizing: border-box;

  .header {
    display: flex;
    align-items: center;
    padding: 8px 0;
    border-bottom: solid #e9edf4 1px;
    justify-content: space-between;

    .price {
      color: #272e40;
      font-weight: bold;
      font-size: 20px;
      line-height: 32px;
    }

    .requestSelling {
      appearance: none;
      border: none;
      width: 80px;
      height: 48px;
      background: none;
      color: #396eff;
      font-size: 14px;
      z-index: 99;
      position: relative;
    }
  }

  .profile {
    display: flex;
    padding-top: 15px;
    background-image: url(../../images/ic_next.svg);
    background-repeat:  no-repeat;
    background-position: right top 31px;

    .thumbnail {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-size: 100% !important;
      margin-right: 20px;
      background-color: #efefef;
      margin-top: 4px;
    }

    .dealerName {
      font-weight: bold;
      font-size: 14px;
      line-height: 24px;
      margin-bottom: 4px;
    }

    .rating {
      font-size: 10px;
      line-height: 16px;
      color: #396eff;
      padding-left: 20px;
      background: url(../../images/ic_star_full.svg) no-repeat left center;
    }
  }
}
