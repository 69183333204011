.layout {
  display: flex;
  width: 100%;
  height: 100%;

  .contentWrapper {
    display: flex;
    width: 100%;
    height: 100%;
    box-sizing: border-box;

    .contentInner {
      display: flex;
      width: 100%;
      height: 100%;
    }
  }
}
